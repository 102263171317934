<template>
    <div>
        <b-modal
            :id="id"
            centered
            :title="title"
            ref="modal-cita"
            ok-title="Guardar"
            cancel-title="Cancelar"
            @ok="onSubmit"
        >
            <b-form>
                <b-row>
                    <b-col sm="7">
                        <b-form-group label="Elige una fecha">
                            <b-form-datepicker
                                id="datepicker-valid"
                                placeholder="No hay fecha seleccionada"
                                :start-weekday="1"
                                size="sm"
                                v-model="fecha"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="5">
                        <b-form-group label="Hora"
                                      label-for="hora"
                                      class="w-50 input-time"
                        >
                            <b-form-select
                                v-model="hora"
                                :options="optionHour"
                                :clearable="false"
                                input-id="hora"
                            />
                        </b-form-group>
                        <b-form-group label="Minutos"
                                      label-for="Minutos"
                                      class="w-50 input-time"
                        >
                            <b-form-select
                                v-model="minutos"
                                :options="optionMinutes"
                                :clearable="false"
                                input-id="minutos"
                            />

                        </b-form-group>

                    </b-col>
                </b-row>
                <b-form-group
                >
                    <label>Asignar comercial</label>
                    <select class="form-control" id="select-comercial" v-model="dataCita.fk_comercial">
                        <option v-for="option in opciones" :value="option.value">{{option.text}}</option>
                    </select>
                </b-form-group>
                <b-form-group
                >
                    <label>Notas predefinidas:</label>
                    <b-form-select
                        v-model="noteSelected"
                        :options="notePredefined"
                        :clearable="false"
                        input-id="notas-pre"
                        @input="seleccionado"
                    />
                </b-form-group>
                <b-form-group label="Notas:">
                    <b-form-textarea
                        type="text"
                        v-model="dataCita.notes"
                        placeholder=""
                        :value="noteSelected"
                        required
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group>
                    <label v-if="statusCita !== true">Marcar como completada</label>
                    <label v-else>Completada</label>
                    <b-form-checkbox
                        :checked="statusCita"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="statusCita"
                    >
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon"/>
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon"/>
                        </span>
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Observaciones:" v-if="statusCita !== false">
                    <b-form-textarea
                        type="text"
                        v-model="dataCita.observaciones"
                        placeholder="Describe cómo ha ido la visita"
                        required
                    ></b-form-textarea>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>

    import {
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BFormSelect,
        BRow,
        BCol,
        BModal,
        BFormCheckbox,
        BFormTextarea,
        BFormDatepicker,
    } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import moment from 'moment'

    export default {
        name: 'ModalCita',
        components: {
            BButton,
            BFormCheckbox,
            BForm,
            BFormInput,
            BFormGroup,
            BModal,
            BFormSelect,
            BRow,
            BCol,
            BFormTextarea,
            BFormDatepicker
        },
        props: {
            dataId: { type: String, default: '' },
            title: { type: String, default: '' },
            id: { type: String, default: '' },
            dataCita: { type: Object, default: '' },
        },
        data () {
            return {
                cita: {},
                users: {},
                userLogged: '',
                statusCita: false,
                fecha: '',
                search: '',
                idCustomer: '',
                customers: [],
                noteSelected: null,
                notePredefined: [
                    { value: null, text: 'Selecciona una nota' },
                    { value: 'Hay que llevar flyers.', text: 'Hay que llevar flyers' },
                    { value: 'Tiene dudas con las facturas.', text: 'Tiene dudas con las facturas' },
                    { value: 'Conocer nuevas promociones.', text: 'Conocer nuevas promociones' }
                ],
                opciones: [
                    {
                        value: '',
                        text: ''
                    }
                ],
                optionHour: [],
                optionMinutes: ['00', '15', '30', '45'],
                hora: '',
                minutos: '',
            }
        },
        methods: {
            seleccionado (valor) {
                this.dataCita.notes = valor + '\n'
            },
            getUsers () {
                const self = this
                useJwt.getUsers()
                    .then(response => {
                        const tmpUsers = response.data.users
                        self.selected = response.data.userLogged
                        for (let comercial in tmpUsers) {
                            self.opciones.push({ text: tmpUsers[comercial]['name'], value: tmpUsers[comercial]['id'] })
                        }
                        this.users = response.data.users
                    })
            },
            onSubmit () {
                const self = this
                let userLogged = JSON.parse(localStorage.getItem('userData'))
                if (self.statusCita !== false) {
                    self.dataCita.estado = 2
                } else {
                    self.dataCita.estado = 1
                }
                this.dataCita.fk_user = userLogged['id']
                this.dataCita.fk_customer = self.dataId
                this.fixDateBackend(this.fecha, this.hora, this.minutos)
                useJwt.storeCita(self.dataCita)
                    .then(response => {
                        //self.hideModal()
                        self.emitConfirmed()
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.message,
                                icon: 'UserIcon',
                                variant: 'success',
                                text: `Ha actualizado el usuario ${self.cita.name} correctamente!`,
                            },
                        })
                    })
            },
            show () {
                this.$bvModal.show(this.id)
            },
            async fixDate () {
                await this.timeHour()

                if (Object.keys(this.dataCita).length !== 0) {
                    let fechaDb = this.dataCita.date_appointment
                    this.fecha = this.formatDate(fechaDb)
                    this.hora = fechaDb.substr(11, 2)
                    this.minutos = fechaDb.substr(14, 2)
                }

                this.show()
            },
            fixDateBackend(fecha, hora, minutos) {
                this.dataCita.date_appointment = fecha + ' ' + hora + ':' + minutos + ':00'
            },
            hideModal () {
                this.$refs['modal-cita'].hide()
            },
            emitConfirmed () {
                this.$emit('confirmed')
            },
            timeHour () {
                let horas = 7
                for (let i = 0; i < 15; i++) {
                    horas += 1
                    this.optionHour.push(horas)
                }
                this.getUsers()
            },
            formatDate (value) {
                moment.locale('es')
                return moment(value).format('YYYY-MM-DD')
            },

        },
    }

</script>
<style>
    .input-time {
        float: left;
    }

    .input-time label {
        font-size: 14px;
    }

    #datepicker-valid {
        padding: 3px 0;
        margin-top: -3px;
    }
</style>
