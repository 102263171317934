<template>
    <b-card
        no-body
        class="border-primary"
    >
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25" v-if="Object.keys(appointment).length === 0">
            <h5 class="mb-0">
                No hay citas pendientes.
            </h5>
        </b-card-header>
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25" v-else>
            <h5 class="mb-0">
                {{ formatDateLetter(appointment.date_appointment) }}
            </h5>
            <b-badge variant="light-danger" v-if="appointment.estado !== 2">
                Pendiente
            </b-badge>
            <b-badge variant="light-success" v-else>
                Completada
            </b-badge>
            <small class="text-muted w-100">Próxima cita</small>
        </b-card-header>

        <b-card-body>
            <ul class="list-unstyled my-1">
                <li>
                    <span class="align-middle"><small>{{ appointment.notes }}</small></span>
                </li>
            </ul>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                v-if="Object.keys(appointment).length === 0"
                @click="emitConfirmed(appointment)"
            >
                Nueva cita
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="emitConfirmed(appointment)"
                v-else
            >
                Editar cita
            </b-button>
        </b-card-body>

    </b-card>
</template>

<script>
    import {
        BCard, BCardHeader, BCardBody, BBadge, BButton,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import moment from 'moment'

    export default {
        directives: {
            Ripple,
        },
        props: {
            appointment: { type: Object, required: true }
        },
        components: {
            BCard,
            BCardHeader,
            BCardBody,
            BBadge,
            BButton,
        },
        data() {
            return {
                cita: {},
                refreshRender: '',
                titleModal: '',
                idCustomer: this.$route.params.idCustomer.toString(),
            }
        },
        methods: {
            formatDateLetter (value) {
                moment.locale('es')
                return moment(value).format('DD MMMM YYYY - H:mm')+'h'
            },
            emitConfirmed(citas) {
                this.cita = citas
                this.$emit('openmodal', this.cita)
            },
        },
    }
</script>

<style>

</style>
