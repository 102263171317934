<template>
    <b-card>

        <b-row>

            <!-- User Info: Left col -->
            <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <b-avatar
                        :src="customerData.avatar"
                        text="avatarText"
                        variant="light"
                        size="104px"
                        rounded
                    />
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1">
                            <h4 class="mb-0">
                                {{ customerData.cli_exc }}
                            </h4>
                            <span class="card-text"><a :href="`mailto:${this.customerData.email}`">{{ customerData.email }}</a></span>
                        </div>
                        <div class="d-flex flex-wrap">
                            <b-avatar
                                variant="light-primary"
                                rounded
                            ><a :href="`tel:${customerData.telefono}`">
                                <feather-icon
                                    icon="PhoneIcon"
                                    size="18"
                                /></a>
                            </b-avatar>
                            <b-avatar
                                class="ml-2"
                                variant="light-primary"
                                rounded
                            >
                                <feather-icon
                                    icon="LayersIcon"
                                    size="18"
                                />
                            </b-avatar>
                            <div class="ml-1">
                                <h5 class="mb-0">
                                    {{ (saldoData.total_pendiente > 0) ? saldoData.total_pendiente : '0' }} €
                                </h5>
                                <small>Saldo pendiente</small>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- User Stats -->
                <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center mr-2">

                    </div>

                </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
                cols="12"
                xl="6"
            >
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                icon="UserIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Nº de Cliente</span>
                        </th>
                        <td class="pb-50">
                            {{ customerData.clave }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                icon="BriefcaseIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Nombre Fiscal</span>
                        </th>
                        <td class="pb-50">
                            {{ customerData.nomfactura }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                icon="MapPinIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Dirección</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            {{ customerData.dir_exc }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon
                                icon="MapIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Localidad</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            {{ customerData.villa_exc }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <feather-icon
                                icon="PhoneIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Persona de contacto</span>
                        </th>
                        <td>
                            {{ customerData.nombre }}
                        </td>
                    </tr>
                </table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import {
        BCard, BButton, BAvatar, BRow, BCol,
    } from 'bootstrap-vue'
    import { avatarText } from '@core/utils/filter'

    export default {
        props: {
            customerData: { type: Object, required: true },
            saldoData: { type: Object, required: true },
            idCustomer: { type: String, required: true },
        },
        components: {
            BCard,
            BButton,
            BRow,
            BCol,
            BAvatar,
            avatarText
        },
        mounted () {
        },
        data() {
            return {
            }
        },
        methods: {
        }

    }
</script>

<style>

</style>
