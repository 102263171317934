<template>
    <div>

        <template v-if="customerData">
            <!-- First Row -->
            <b-row>
                <b-col
                    cols="12"
                    xl="9"
                    lg="8"
                    md="7"
                >
                    <customer-view-customer-info-card
                        :customer-data="customerData"
                        :saldo-data="saldoData"
                        :id-customer="idCustomer"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="5"
                    xl="3"
                    lg="4"
                >
                    <customer-next-appointment-card :appointment="nextAppointment" @openmodal="storeCita"
                                                    v-if="refreshRender"/>
                </b-col>
            </b-row>

            <b-row>
                <b-col
                    cols="12"
                    lg="6"
                >
                    <customer-view-customer-timeline-card
                        :visits-data="visitsData" v-if="visitsData.length !== 0 && refreshRender !== false"
                    />
                    <b-card v-if="visitsData.length === 0" title="No existen visitas para este cliente"></b-card>
                </b-col>
                <b-col
                    cols="12"
                    lg="6"
                >
                    <appointments-list-customer :data-visits="listVisitsCustomer" v-if="refreshRender !== false" />
                </b-col>
            </b-row>

            <!--<invoice-list />-->
        </template>
        <modal-cita
            :data-id="idCustomer"
            :data-cita="idCita"
            id="crear-cita"
            :title="titleModal"
            ref="modalCita"
            @confirmed="renderAppointments"
            v-if="refreshRender !== false"
        />
    </div>
</template>

<script>
    import {
        BRow, BCol, BAlert, BLink, BCard
    } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import CustomerNextAppointmentCard from './CustomerNextAppointmentCard.vue'
    import CustomerViewCustomerInfoCard from './CustomerViewCustomerInfoCard'
    import CustomerViewCustomerTimelineCard from './CustomerViewCustomerTimelineCard'
    import AppointmentsListCustomer from './AppointmentsListCustomer'
    import ModalCita from './VisitModal.vue'

    export default {
        components: {
            BRow,
            BCol,
            BAlert,
            BLink,
            BCard,
            useJwt,
            ModalCita,
            CustomerNextAppointmentCard,
            AppointmentsListCustomer,
            CustomerViewCustomerInfoCard,
            CustomerViewCustomerTimelineCard
        },
        data () {
            return {
                customerData: {},
                idCustomer: this.$route.params.idCustomer.toString(),
                idCita: {},
                saldoData: {},
                listVisitsKey: 0,
                visitsData: [],
                listVisitsCustomer: [],
                nextAppointment: {},
                refreshRender: true,
                titleModal: '',
            }
        },
        mounted () {
            this.getDetailsCliente(this.idCustomer.toString())
            this.getVisitasCliente(this.idCustomer.toString())
        },
        methods: {
            async getDetailsCliente (id) {
                const self = this
                await useJwt.getDetailsCustomer({ 'id': id })
                    .then(response => {
                        self.customerData = response.data.customer[0]
                        self.saldoData = response.data.saldo
                    })
            },
            getVisitasCliente (id) {
                const self = this
                id = self.idCustomer
                useJwt.getVisitsCustomer({ 'id': id })
                    .then(response => {
                        self.visitsData = response.data.visits.slice(0, 3)
                        self.listVisitsCustomer = response.data.visits
                        self.nextAppointment = response.data.cita
                    })
            },
            crearCita () {
                this.$refs.modalCita.fixDate()
            },

            storeCita (cita) {
                this.idCita = cita

                if (Object.keys(cita).length !== 0) {
                    // Edita una cita
                    // console.log(this.idCita)
                    this.titleModal = 'Editar cita'
                    this.crearCita()
                } else {
                    // Crea una cita
                    this.titleModal = 'Crear nueva cita'
                    this.crearCita()
                }
            },
            renderAppointments () {
                console.log('1')
                this.refreshRender = false
                this.$nextTick(() => {
                    // Add the component back in
                    this.getDetailsCliente(this.idCustomer.toString())
                    this.getVisitasCliente(this.idCustomer.toString())
                })
                this.refreshRender = true
            },
        },
    }
</script>

<style>

</style>
