<template>
    <div class="row">
        <div class="col-12">
            <b-card
                no-body
                class="mb-0"
            >
                <div class="m-2">

                    <!-- Table Top -->
                    <b-row>

                        <!-- Per Page -->
                        <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                           <!-- <b-button
                                variant="primary"
                                @click="crearCita()"
                            >
                                <feather-icon icon="CalendarIcon" id="bt-new" /><sup>+</sup>
                            </b-button>-->
                        </b-col>
                        <!-- Search -->
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input
                                    v-model="searchVisitas"
                                    class="d-inline-block mr-1"
                                    placeholder="Buscar..."
                                    @keyup="searchVisitas"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-table
                    :stacked="options.stacked"
                    class="position-relative"
                    :items="dataVisits"
                    striped
                    responsive
                    :fields="tableColumns"
                    :per-page="options.perPageOptions"
                    :current-page="options.currentPage"
                    primary-key="id"
                    show-empty
                    empty-text="No matching records found"
                    id="tabla-clientes"
                >

                    <template #cell(show_details)="row">
                        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                        <span class="show-visits"
                              v-model="row.detailsShowing"
                              @click="row.toggleDetails"
                        >
                            <feather-icon icon="ChevronRightIcon" v-if="row.detailsShowing === false"/>
                            <feather-icon icon="ChevronDownIcon" v-if="row.detailsShowing === true"/>
                        </span>
                        <span class="edit-visits" v-if="row.item.date_visited === null"
                              @click="goDetails(row)"
                        >
                            <feather-icon icon="EditIcon"/>
                        </span>
                    </template>
                    <template #cell(date_appointment)="row">
                        <span
                            v-if="row.item.date_appointment !== null">{{ formatDate(row.item.date_appointment) }}</span>
                        <span v-else> </span>
                    </template>
                    <template #cell(date_visited)="row">
                        <span v-if="row.item.date_visited !== null">{{ formatDate(row.item.date_visited) }}</span>
                        <span v-else> </span>
                    </template>
                    <!-- full detail on click -->
                    <template #row-details="row">
                        <b-card>
                            <b-row class="mb-2">
                                <b-col
                                    md="12"
                                    class="mb-1"
                                >
                                    <strong>Notas: </strong>
                                    {{ row.item.notes }}
                                </b-col>
                            </b-row>

                            <b-button
                                size="sm"
                                variant="outline-primary"
                                @click="row.toggleDetails"
                            >
                                Ocultar
                            </b-button>
                        </b-card>
                    </template>
                </b-table>
            </b-card>
        </div>
        <modal-cita :data-id="idCustomer" :data-cita="idCita" id="editar-cita" :title="titleModal" ref="btModal"/>
    </div>
</template>

<script>

    import { BRow, BCol, BFormInput, BCard, BTable, BFormCheckbox, BButton } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import moment from 'moment'
    import CustomerNextAppointmentCard from './CustomerNextAppointmentCard.vue'
    import ModalCita from './VisitModal'

    export default {
        name: 'Visitas',
        components: {
            BRow,
            BCol,
            BFormInput,
            BCard,
            BTable,
            BFormCheckbox,
            BButton,
            ModalCita,
            CustomerNextAppointmentCard,
            useJwt
        },
        props: {
            dataVisits: { type: Array, required: true }
        },
        data () {
            return {
                searchVisitas: '',
                titleModal: '',
                idCustomer: this.$route.params.idCustomer.toString(),
                visitas: [],
                idCita: {},
                setTimeoutSearch: '',
                refreshRender: true,
                options: {
                    stacked: false,
                    perPageOptions: null,
                    currentPage: 1,
                    rows: null,
                    filter: null,
                },
                tableColumns: [
                    /*{
                        label: this.estado,
                        sortable: false,
                    },*/
                    {
                        label: '',
                        key: 'show_details',
                        sortable: false,
                        class: 'edit-button',
                    },
                    {
                        label: 'Comercial',
                        key: 'comercial',
                        sortable: true,
                    },
                    {
                        label: 'Observaciones',
                        key: 'observaciones',
                        sortable: true,
                    },
                    {
                        label: 'Visitado',
                        key: 'date_visited',
                        sortable: true,
                    },
                ],
            }
        },
        methods: {
            formatDate (value) {
                return moment(value).format('DD-MM-YYYY')
            },
            goDetails(row) {
                this.idCita = row.item
                this.$refs.btModal.fixDate()
            },
            crearCita() {
                this.$refs.btModal.fixDate()
            },
        },
    }

</script>

<style>
    .edit-button {
        width: 70px;
        padding: 0.72rem 1rem !important;
    }

    .edit-visits {
        float: right;
    }

    .show-visits:hover,
    .edit-visits:hover {
        cursor: pointer;
    }
    sup {
        float: right;
        margin-left: 0px;
        margin-top: 5px;
        font-size: 15px;
    }

</style>
