<template>
    <div>
        <b-card title="Últimas visitas realizadas">
            <app-timeline>

                <!-- 12 INVOICES HAVE BEEN PAID -->
                <app-timeline-item :data="visitsData" v-for="visita in visitsData" :variant="colors(coloresTL)">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6><b>{{ formatDate(visita.date_visited) }}</b></h6>
                        <small class="text-muted" v-if="visita.days_diff < 30 && visita.days_diff !== ''">Hace {{ visita.days_diff }}
                            días</small>
                        <small class="text-muted" v-else>Hace más de un mes</small>
                    </div>
                    <p>Motivo cita: {{ visita.notes }}</p>
                    <p>Observaciones: {{ visita.observaciones }}</p>
                    <b-media>
                        <template #aside>
                            <b-avatar
                                variant="success"
                                size="32"
                                :src="visita.comercial"
                                :text="avatarText(visita.comercial)"
                            />
                        </template>
                        <h6 class="mb-0">{{ visita.comercial }}</h6>
                        <p class="mb-0 mt-0 text-muted">
                            <a :href="`mailto:${visita.email}`"><small>{{ visita.email }}</small></a>
                        </p>
                    </b-media>
                </app-timeline-item>
                <!--<app-timeline-item variant="warning">

                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6>Client Meeting</h6>
                        <small class="text-muted">45 min ago</small>
                    </div>
                    <p>Project meeting with john @10:15am.</p>
                    <b-media>
                        <template #aside>
                            <b-avatar :src="require('@/assets/images/avatars/8-small.png')"/>
                        </template>
                        <h6>John Doe (Client)</h6>
                        <p class="mb-0">
                            CEO of Infibeam
                        </p>
                    </b-media>
                </app-timeline-item>
                <app-timeline-item
                    variant="info"
                    title="Create a new project for client"
                    subtitle="Add files to new design folder"
                    time="2 days ago"
                />-->
            </app-timeline>
        </b-card>
    </div>
</template>

<script>
    import {
        BCard, BImg, BMedia, BAvatar,
    } from 'bootstrap-vue'
    import { avatarText } from '@/@core/utils/filter'
    import AppTimeline from '@/@core/components/app-timeline/AppTimeline.vue'
    import AppTimelineItem from '@/@core/components/app-timeline/AppTimelineItem.vue'
    import moment from 'moment'

    export default {
        components: {
            BCard,
            BImg,
            BMedia,
            BAvatar,
            avatarText,
            AppTimeline,
            AppTimelineItem
        },
        props: {
            visitsData: { type: Array, required: true }
        },
        setup () {
            return {
                avatarText,
            }
        },
        data() {
            return {
                coloresTL: ["primary","secondary","warning","danger","info"]
            }
        },
        methods: {
            formatDate (value) {
                return moment(value).format('DD-MM-YYYY')
            },
            colors(color) {
                return color[Math.floor(Math.random()*color.length)];
            },
        }
    }

</script>

<style>

</style>
